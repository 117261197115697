import React, { useEffect, useCallback, useState } from 'react';
import { useRouter } from 'next/router'
import { signin, authenticate, isAuth, setCookie, reloadtoken } from '../actions/auth'
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from './ui/form';
import { Input } from './ui/input';
import { Button } from './ui/button';
import { useForm } from 'react-hook-form';
import { Alert, AlertDescription } from './ui/alert';
import { AlertCircle, Loader2, EyeIcon, EyeOff } from 'lucide-react';
import _ from 'lodash';
import Head from 'next/head';


const LoginPage = () => {
  const [user, setUser] = useState({ email: "", password: "" });
  const [alert, setAlert] = useState({ message: "", error: "", loading: false });
  const [showPassword, setShowPassword] = React.useState(false);

  const form = useForm()
  const Router = useRouter();

  const handleClickShowPassword = useCallback(() => setShowPassword((show) => !show), [setShowPassword]);

  useEffect(() => {
    const email = Router.query.email as string;
    const password = Router.query.password as string;
    setUser({ email: email || "", password: password || "" });
  }, [Router.query]);

  const submit = useCallback((e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setAlert({ ...alert, message: "", error: "", loading: true });
    signin(user)
      .then((res) => {
        if (res.error) { return setAlert({ ...alert, message: "", error: res.error, loading: false }) }

        setAlert({
          ...alert,
          message: "Inicio de sesión exitoso, redireccionando..",
          error: "",
          loading: true,
        });

        return authenticate(res, () => {
          if (Router.query.returnurl && Router.query.returnurl != "/" && typeof Router.query.returnurl == "string") {
            Router.push(Router.query.returnurl);
          } else {
            Router.push(_.get(res, "user.startPage") ?? "/");
          }
        });
      })
      .catch(() => {
        setAlert({ ...alert, message: "", error: "Error", loading: false });
      });
  }, [alert, user, Router]);

  useEffect(() => {
    if (Router.query.token && process.browser) {
      setCookie("token", Router.query.token);
      setCookie("onApp", "true");
      reloadtoken().then((res) => {
        if (!res || res.error) { Router.push("/login") }
        authenticate(res, () => Router.push("/"));
      });
    }

    if (isAuth()) { Router.push("/") }
  }, [Router.query]);

  const handleChange = useCallback((name: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
    setUser({ ...user, [name]: e.target.value });
    setAlert({ ...alert, message: "", error: "", loading: false });
  }, [user, alert, setUser, setAlert]);

  const { message, error, loading } = alert;
  const { email, password } = user;
  

  return (process.browser && Router && Router.query && Router.query.token) ?
    <React.Fragment>
      <div className='w-full min-h-[100vh] flex justify-center items-center'>
        <Loader2 className="h-14 w-14 animate-spin text-primary" />
      </div>
    </React.Fragment>
    : <React.Fragment>
      <div>
        <h2 className="text-[27px] font-semibold tracking-tight">Hola, bienvenido a Ventia!</h2>
        <p className="text-[18px] tracking-tight  font-normal text-neutral-500">Iniciar sesión</p>
      </div>
      <div>
        <div className='min-h-[50px] py-2'>
          {error && <Alert variant="destructive">
            <AlertCircle className="h-4 w-4" />
            <AlertDescription>
              {error}
            </AlertDescription>
          </Alert>}
        </div>
      </div>
      <Form {...form}>
        <form onSubmit={submit}>
          <FormField
            control={form.control}
            name="login"
            render={() => <React.Fragment>
              <FormItem>
                <FormLabel>Correo electronico <span className='text-primary'>*</span></FormLabel>
                <FormControl>
                  <Input
                    placeholder="ejemplo@gmail.com"
                    required className='py-[22px]'
                    value={email}
                    onChange={handleChange('email')}
                    type='email'
                    autoFocus />
                </FormControl>
                <FormMessage />
              </FormItem>
              <FormItem className='mt-5'>
                <FormLabel>Contraseña <span className='text-primary'>*</span></FormLabel>
                <div className='relative'>
                  <FormControl>
                    <Input
                      placeholder=""
                      required
                      className='py-[22px] pr-[70px]'
                      onChange={handleChange('password')}
                      value={password}
                      type={showPassword ? 'text' : 'password'} />
                  </FormControl>
                  <button
                    type='button'
                    className='absolute top-0 right-0 w-[50px] flex justify-center items-center h-full text-neutral-400'
                    onClick={handleClickShowPassword}>
                    {showPassword ? <EyeOff aria-hidden="true" size={21} /> : <EyeIcon aria-hidden="true" size={21} />}
                  </button>
                </div>
                <FormMessage />
              </FormItem>
            </React.Fragment>}
          />
          <Button type="submit" className='w-full mt-7 py-[24px]' disabled={loading}>
            {loading
              ? <Loader2 className="mr-2 h-4 w-4 animate-spin" />
              : 'Ingresar'}
          </Button>
        </form>
      </Form>
    </React.Fragment >
};

export default LoginPage;